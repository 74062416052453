"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringifyDistance = void 0;
const numbers_1 = require("./numbers");
const stringifyDistance = (distance) => {
    const MILES_IN_METRE = 0.000621371;
    const FEET_IN_METRE = 3.28084;
    const distanceMiles = numbers_1.convertValue(distance, MILES_IN_METRE, 1);
    const distanceFeet = numbers_1.convertValue(distance, FEET_IN_METRE, 0);
    return distanceFeet < 528 ? `${distanceFeet} ft` : `${distanceMiles} miles`;
};
exports.stringifyDistance = stringifyDistance;
