"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.promiseAny = exports.isAggregateError = void 0;
// @ts-ignore
const promiseAnyFn = require("promise.any");
const isAggregateError = (error) => {
    return !!error.errors && error instanceof Error;
};
exports.isAggregateError = isAggregateError;
const promiseAny = (tasks) => {
    if (tasks.length === 0) {
        return undefined;
    }
    return promiseAnyFn(tasks);
};
exports.promiseAny = promiseAny;
