"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.thousandifyFullUnits = exports.getStructuredPriceFormat = exports.CurrencyTypeOption = exports.getAmountUnitStrings = exports.getPriceSymbolString = void 0;
const lodash_1 = require("lodash");
const interfaces_1 = require("@hip/interfaces");
const CURRENCY_SEPARATORS = {
    GBP: '.',
    EUR: ',',
    RON: '.',
    PLN: ',',
};
const getPriceSymbolString = (currency) => {
    const { symbol, renderOrder, spaceDelimited } = interfaces_1.CURRENCIES[currency];
    if (spaceDelimited) {
        return renderOrder === interfaces_1.CurrencyRenderOrder.AMOUNT_FIRST
            ? ` ${symbol}`
            : `${symbol} `;
    }
    return symbol;
};
exports.getPriceSymbolString = getPriceSymbolString;
const getAmountUnitStrings = (amount) => {
    const fullUnits = Math.floor(amount);
    const fractionalUnitsString = (amount - fullUnits).toFixed(2).split('.')[1];
    const fullUnitsString = exports.thousandifyFullUnits(fullUnits);
    return {
        fullUnits: fullUnitsString,
        fractionalUnits: fractionalUnitsString,
    };
};
exports.getAmountUnitStrings = getAmountUnitStrings;
var CurrencyTypeOption;
(function (CurrencyTypeOption) {
    CurrencyTypeOption["FULL_UNITS"] = "fullUnits";
    CurrencyTypeOption["SEPARATOR"] = "separator";
    CurrencyTypeOption["FRACTIONAL_UNITS"] = "fractionalUnits";
    CurrencyTypeOption["SYMBOL"] = "symbol";
})(CurrencyTypeOption = exports.CurrencyTypeOption || (exports.CurrencyTypeOption = {}));
const getStructuredPriceFormat = (price) => {
    const { fullUnits, fractionalUnits } = exports.getAmountUnitStrings(price.amount);
    const symbolString = exports.getPriceSymbolString(price.currency);
    const separator = CURRENCY_SEPARATORS[price.currency];
    const currencyConfig = interfaces_1.CURRENCIES[price.currency];
    const structuredPrice = [
        { type: CurrencyTypeOption.FULL_UNITS, text: fullUnits.toString() },
        { type: CurrencyTypeOption.SEPARATOR, text: separator },
        {
            type: CurrencyTypeOption.FRACTIONAL_UNITS,
            text: fractionalUnits.toString(),
        },
    ];
    currencyConfig.renderOrder === interfaces_1.CurrencyRenderOrder.SYMBOL_FIRST
        ? structuredPrice.unshift({
            type: CurrencyTypeOption.SYMBOL,
            text: symbolString,
        })
        : structuredPrice.push({
            type: CurrencyTypeOption.SYMBOL,
            text: symbolString,
        });
    return structuredPrice;
};
exports.getStructuredPriceFormat = getStructuredPriceFormat;
const thousandifyFullUnits = (number) => {
    const numString = number.toString();
    if (numString.length > 3) {
        const revNumStringArray = [...numString.split('')].reverse();
        const chunked = lodash_1.chunk(revNumStringArray, 3);
        const thousandifiedChunks = chunked.map(chunk => chunk.length === 3 ? [...chunk, ','] : chunk);
        return lodash_1.flattenDeep(thousandifiedChunks).reverse().join('');
    }
    return numString;
};
exports.thousandifyFullUnits = thousandifyFullUnits;
