"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOpcoCurrency = exports.mergeChannelResponses = exports.hydrateMxtBom = exports.hydrateMxtGroup = exports.hydrateMxtAssembly = exports.hydrateMxtProduct = exports.batchEansForChannel = exports.getBomEansAndQty = exports.getMarxentBomEans = void 0;
const lodash_1 = require("lodash");
const interfaces_1 = require("@hip/interfaces");
const getMarxentBomEans = (bom) => lodash_1.uniq(lodash_1.flattenDeep(bom.groups.map(group => lodash_1.flattenDeep(
// flattenDeep not recursively flattening - TechDebt ticket: https://jira.kfplc.com/browse/HIP-3831
group.assemblies.map(assembly => lodash_1.flattenDeep(assembly.products.map(product => [assembly.ean, product.ean]))))))).filter(item => item);
exports.getMarxentBomEans = getMarxentBomEans;
const getBomEansAndQty = (bom) => lodash_1.uniq(lodash_1.flattenDeep(bom.groups.map(group => lodash_1.flattenDeep(
// flattenDeep not recursively flattening - TechDebt ticket: https://jira.kfplc.com/browse/HIP-3831
group.assemblies.map(assembly => lodash_1.flattenDeep(assembly.products.map(product => [
    { ean: assembly.ean, qty: assembly.quantity },
    { ean: product.ean, qty: assembly.quantity },
]))))))).filter(item => item);
exports.getBomEansAndQty = getBomEansAndQty;
const batchEansForChannel = (eans, batchSize = 100) => lodash_1.chunk(eans, batchSize);
exports.batchEansForChannel = batchEansForChannel;
const hydrateMxtProduct = (products, mxtProduct, opco) => {
    const matchedProduct = products.find(product => product.attributes.ean === mxtProduct.ean);
    if (!matchedProduct) {
        return null;
    }
    const productImage = lodash_1.get(matchedProduct, 'attributes.mediaObjects[0].url', '');
    return Object.assign(Object.assign({}, mxtProduct), { name: matchedProduct.attributes.name, unitPrice: {
            amount: lodash_1.get(matchedProduct, 'attributes.pricing.currentPrice.amountIncTax', 0),
            currency: lodash_1.get(matchedProduct, 'attributes.pricing.currencyCode as CURRENCY', exports.getOpcoCurrency(opco)),
        }, image: productImage.replace('{width}', '400').replace('{height}', '400'), productStatus: matchedProduct.attributes.purchasable
            ? 'Available'
            : 'Unavailable', primaryCategoryId: matchedProduct.attributes.primaryCategoryId, pdpURL: matchedProduct.attributes.pdpURL });
};
exports.hydrateMxtProduct = hydrateMxtProduct;
const hydrateMxtAssembly = (products, mxtAssembly, opco) => {
    const assemblyProducts = mxtAssembly.products
        .map(mxtProduct => exports.hydrateMxtProduct(products, mxtProduct, opco))
        .filter(product => product);
    if (assemblyProducts.length) {
        const channelAssemblyProduct = products.find(product => product.attributes.ean === mxtAssembly.ean);
        const name = channelAssemblyProduct
            ? channelAssemblyProduct.attributes.name
            : mxtAssembly.name;
        const assemblySubtotal = assemblyProducts.reduce((acc, curr) => {
            acc.amount += curr.unitPrice.amount * curr.quantity;
            return acc;
        }, { amount: 0, currency: exports.getOpcoCurrency(opco) });
        return Object.assign(Object.assign({}, mxtAssembly), { name, quantity: 1, products: assemblyProducts, unitPrice: assemblySubtotal });
    }
    return null;
};
exports.hydrateMxtAssembly = hydrateMxtAssembly;
const hydrateMxtGroup = (products, mxtGroup, opco) => {
    const groupAssemblies = mxtGroup.assemblies
        .map(mxtAssembly => exports.hydrateMxtAssembly(products, mxtAssembly, opco))
        .filter(assembly => assembly);
    if (groupAssemblies.length) {
        const groupSubtotal = groupAssemblies.reduce((acc, curr) => {
            acc.amount += curr.unitPrice.amount * curr.quantity;
            return acc;
        }, { amount: 0, currency: exports.getOpcoCurrency(opco) });
        return Object.assign(Object.assign({}, mxtGroup), { assemblies: groupAssemblies, subtotal: groupSubtotal });
    }
    return null;
};
exports.hydrateMxtGroup = hydrateMxtGroup;
const hydrateMxtBom = (mxtBom, products, missingProducts, opco) => {
    const opcoCurrency = exports.getOpcoCurrency(opco);
    const missingEans = missingProducts.map(product => product.ean);
    const hydratedGroups = mxtBom.groups
        .map(group => exports.hydrateMxtGroup(products, group, opco))
        .filter(group => group);
    if (hydratedGroups.length) {
        const bomSubTotal = hydratedGroups.reduce((acc, curr) => {
            acc.amount = parseFloat((acc.amount + curr.subtotal.amount).toFixed(2));
            return acc;
        }, { amount: 0, currency: opcoCurrency });
        return Object.assign(Object.assign({}, mxtBom), { missingEans,
            missingProducts, subTotal: bomSubTotal, groups: hydratedGroups });
    }
    return Object.assign(Object.assign({}, mxtBom), { missingEans,
        missingProducts, subTotal: {
            amount: 0,
            currency: opcoCurrency,
        }, groups: [] });
};
exports.hydrateMxtBom = hydrateMxtBom;
const mergeChannelResponses = (responses) => responses.reduce((acc, curr) => {
    acc.meta.warnings = [...acc.meta.warnings, ...curr.meta.warnings];
    acc.data = [...acc.data, ...curr.data];
    return acc;
}, { meta: { warnings: [] }, data: [] });
exports.mergeChannelResponses = mergeChannelResponses;
const opcoCurrency = {
    BQUK: interfaces_1.CURRENCY.GBP,
    CAFR: interfaces_1.CURRENCY.EUR,
    BDFR: interfaces_1.CURRENCY.EUR,
    BDRO: interfaces_1.CURRENCY.RON,
    CAPL: interfaces_1.CURRENCY.PLN,
    TPUK: interfaces_1.CURRENCY.GBP,
    BQIE: interfaces_1.CURRENCY.EUR,
    BQGG: interfaces_1.CURRENCY.GBP,
    BQJE: interfaces_1.CURRENCY.GBP,
    TPGG: interfaces_1.CURRENCY.GBP,
    TPJE: interfaces_1.CURRENCY.GBP,
    TPIE: interfaces_1.CURRENCY.EUR,
};
const getOpcoCurrency = (opco) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return opcoCurrency[opco.toUpperCase()];
};
exports.getOpcoCurrency = getOpcoCurrency;
