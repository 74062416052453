"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShortDayOfWeekName = exports.getDayOfTheWeekName = exports.convertTimeToAmPm = void 0;
const convertTimeToAmPm = (time) => {
    const timeString12hr = new Date('1970-01-01T' + time + 'Z').toLocaleTimeString('en-GB', {
        timeZone: 'UTC',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
    });
    return timeString12hr.split(' ').join('');
};
exports.convertTimeToAmPm = convertTimeToAmPm;
const getDayOfTheWeekName = (url) => new URL(url).pathname.match(/[a-zA-Z]/g).join('');
exports.getDayOfTheWeekName = getDayOfTheWeekName;
const getShortDayOfWeekName = (url) => {
    const dayOfWeekName = exports.getDayOfTheWeekName(url);
    return dayOfWeekName.slice(0, 3);
};
exports.getShortDayOfWeekName = getShortDayOfWeekName;
