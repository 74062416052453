"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFeatureTogglesRoute = exports.createPrintBomShareRoute = exports.createPrintBomProjectRoute = exports.createBomShareRoute = exports.createBomProjectRoute = exports.createShareRoute = exports.createProjectRoute = exports.baseShareRoute = void 0;
exports.baseShareRoute = ':shareId?';
const baseProjectRoute = ':projectId';
const baseDesignRoute = ':designId?';
const createProjectRoute = (projectId = baseProjectRoute, designId = baseDesignRoute) => {
    let design = projectId === baseProjectRoute ? baseDesignRoute : '';
    design =
        projectId !== baseProjectRoute && designId !== baseDesignRoute
            ? designId
            : design;
    return `/3d-planner/project/${projectId}/design/${design}`;
};
exports.createProjectRoute = createProjectRoute;
const createShareRoute = (shareId) => shareId ? `/3d-planner/share/${shareId}` : '/3d-planner/share';
exports.createShareRoute = createShareRoute;
const createBomProjectRoute = (projectId = baseProjectRoute, designId = baseDesignRoute.slice(0, -1)) => {
    let design = projectId === baseProjectRoute ? baseDesignRoute : '';
    design =
        projectId !== baseProjectRoute && designId !== baseDesignRoute
            ? designId
            : design;
    return `/3d-planner/project/${projectId}/design/${design}/bom`;
};
exports.createBomProjectRoute = createBomProjectRoute;
const createBomShareRoute = (shareId) => `/3d-planner/share/${shareId}/bom`;
exports.createBomShareRoute = createBomShareRoute;
const createPrintBomProjectRoute = (projectId = baseProjectRoute, designId = baseDesignRoute.slice(0, -1)) => {
    let design = projectId === baseProjectRoute ? baseDesignRoute : '';
    design =
        projectId !== baseProjectRoute && designId !== baseDesignRoute
            ? designId
            : design;
    return `/3d-planner/project/${projectId}/design/${design}/bom/print`;
};
exports.createPrintBomProjectRoute = createPrintBomProjectRoute;
const createPrintBomShareRoute = (shareId) => `/3d-planner/share/${shareId}/bom/print`;
exports.createPrintBomShareRoute = createPrintBomShareRoute;
const createFeatureTogglesRoute = () => '/feature-toggles';
exports.createFeatureTogglesRoute = createFeatureTogglesRoute;
